interface Metric {
    eventDetail?: string;
    eventValue?: string;
    eventType?: string;
    eventContext?: string;
    eventInteractionMetaData?: {};
    errorFormFields?: any;
    eventSource?: string;
}

/**
 * Checks if Panorama is enabled in current page
 * @returns { boolean }
 */
export const isPanoramaEnabled = (): boolean => {
    return window.panorama && typeof window.panorama === 'function';
};

/**
 * Checks if Panorama is enabled and empties existing metric buffer
 */
const checkIfCanEmptyBuffer = () => {
    if (isPanoramaEnabled()) {
        initialBuffer.forEach((metric) => {
            emitPanoramaCustomEvent(metric);
        });
    }
};

// Maintain metrics until Panorama is enabled on the page
const initialBuffer: Metric[] = [];

/**
 * Push the metrics to internal buffer until Panorama is enabled
 * @param { Metric } metric
 */
const pushToBuffer = (metric: Metric) => {
    initialBuffer.push(metric);
    checkIfCanEmptyBuffer();
};

/**
 * Emits Panorama custom event
 * @param { Metric } metric
 */
export const emitPanoramaCustomEvent = (metric: Metric) => {
    if (!isPanoramaEnabled()) {
        pushToBuffer(metric);
    } else {
        window.panorama('trackCustomEvent', {
            ...metric,
            timestamp: Date.now(),
        });
    }
};
